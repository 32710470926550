import styled from "styled-components";
import { foundations } from "@r114dev/rds";

const { getColor } = foundations;

const IconButtonStyledComponents = {
  StyledButton: styled.button`
    width: 40px;
    height: 40px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: none;
    border: none;
    padding: 0;

    cursor: pointer;

    transition: 1s;

    > svg {
      width: 32px;
      height: 32px;

      display: block;
      margin: 0 auto;
      ${getColor("fill", "black", "white")}
      flex-shrink: 0;
    }
  `,
};

export default IconButtonStyledComponents;
