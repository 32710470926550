import { getOne, setOne } from "../firebase/firestore";
import { GetRoleResponse, Role } from "../../models/role";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore/lite";

export const getRole = async (id: string): Promise<Role> => {
  try {
    const data = (await getOne("roles", id)) as GetRoleResponse;
    if (data) {
      return data.role;
    } else {
      await setRole(id, "guest");
      return "guest";
    }
  } catch (e) {
    return "guest";
  }
};

export const setRole = async (id: string, role: string) => {
  try {
    await setOne("roles", id, { role });
  } catch (e) {
    console.error(e);
  }
};

export const getRoleWithToken = async (token: string = "") => {
  const q = query(
    collection(getFirestore(), "auth"),
    where("token", "==", token)
  );

  const { docs, empty } = await getDocs(q);

  if (empty) {
    return "guest";
  }

  return docs[0].data().role;
};
