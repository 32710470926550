import { FC, useContext, useEffect, useRef, useState } from "react";

import { usePathname } from "next/navigation";

import { HamburgerDrawerProps } from "./types";
import HamburgerDrawerStyledComponents from "./styled";

import { icons } from "@r114dev/rds";

import IconButton from "../IconButton";
import SidePanelMenu from "../SidePanelMenu";
import Drawer from "../Drawer";
import { MenuContext } from "../Layout/context";
import { Menu } from "../../models/menu";
import {
  getChildrenMenusFromAllMenus,
  getParentMenus,
} from "../../utils/network/menu";

const {
  outlined: {
    arrows: { LeftSVG },
  },
} = icons;

const { Header, ButtonGroup, Body, MenuListTitle } =
  HamburgerDrawerStyledComponents;

const HamburgerDrawer: FC<HamburgerDrawerProps> = () => {
  return (
    <Drawer trigger={<IconButton type="hamburger" />}>
      {({ handleClose }) => {
        return <DrawerContents onClose={handleClose} />;
      }}
    </Drawer>
  );
};

export default HamburgerDrawer;

const DrawerContents: FC<{ onClose: () => void }> = ({ onClose }) => {
  const pathname = usePathname();

  const { menus } = useContext(MenuContext);

  const menuListRef = useRef<HTMLDivElement>(null);
  const [selectedDomain, setSelectedDomain] = useState<Menu>();

  useEffect(() => {
    if (menuListRef.current) {
      if (selectedDomain) {
        menuListRef.current.style.setProperty("transform", "translateX(-50%)");
      } else {
        menuListRef.current.style.removeProperty("transform");
      }
    }
  }, [selectedDomain]);

  const handleMobileDomainClick = (menu: Menu) => {
    setSelectedDomain(menu);
  };

  const handleMenuListTitleClick = () => {
    setSelectedDomain(undefined);
  };

  const parentMenus = getParentMenus(menus);

  return (
    <>
      <Header>
        <ButtonGroup>
          <IconButton type="close" onClick={onClose} />
        </ButtonGroup>
      </Header>
      <Body ref={menuListRef}>
        <ul>
          {parentMenus
            .filter(({ visible }) => visible)
            .map((domain) => {
              const { id, path, title } = domain;
              const currentDomain = pathname.split("/")?.[1];
              const isActive = `${path.split("/")[1]}` === currentDomain;

              return (
                <li
                  key={id}
                  className={isActive ? "active" : undefined}
                  onClick={() => handleMobileDomainClick(domain)}
                >
                  {title}
                </li>
              );
            })}
        </ul>
        {selectedDomain ? (
          <ul>
            <MenuListTitle onClick={handleMenuListTitleClick}>
              <LeftSVG />
              <h2>{selectedDomain.title}</h2>
            </MenuListTitle>
            <SidePanelMenu
              currentPath={pathname}
              items={getChildrenMenusFromAllMenus(selectedDomain, menus)}
              isMobile
              onItemClick={onClose}
            />
          </ul>
        ) : null}
      </Body>
    </>
  );
};
