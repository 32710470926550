import styled from "styled-components";

import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

const LabelStyledComponents = {
  Container: styled.span`
    height: 24px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 12px;

    border: 1px solid;
    ${getColor("border-color", "gray200", "gray800")};
    border-radius: 4px;

    ${getColor("color", "gray900", "gray100")};
    ${typography.body03M}

    cursor: pointer;
  `,
};

export default LabelStyledComponents;
