import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import { headerHeight, tabletBreakPoint } from "../../constants/size";

const { getColor, typography } = foundations;

const SearchStyledComponents = {
  Container: styled.div`
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    flex-direction: column;

    position: fixed;
    top: ${headerHeight}px;
    left: 0;
    z-index: 10;

    padding: 40px 0 32px;

    ${getColor("background", "white", "black")};
    box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.04);

    @media (max-width: ${tabletBreakPoint}px) {
      height: 100%;

      display: block;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      padding: 0;
    }
  `,

  TopArea: styled.div`
    width: 1080px;

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;

      display: flex;
      align-items: center;
      gap: 12px;

      position: fixed;
      top: 0;
      left: 0;

      padding: 12px 20px;

      border-bottom: 1px solid;
      ${getColor("border-bottom-color", "gray200", "gray800")};
    }
  `,

  BottonArea: styled.div`
    width: 1080px;

    display: flex;
    flex-direction: column;
    gap: 6px;

    margin-top: 64px;

    h4 {
      ${typography.body03M}
    }
    p {
      display: flex;
      gap: 8px;

      margin: 0;
    }

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;
      overflow-y: auto;
      padding: 24px 20px;
    }
  `,

  CloseButton: styled.button`
    display: none;
    flex: 1;

    white-space: nowrap;
    border: none;
    background: none;
    padding: 0;

    cursor: pointer;

    ${typography.body01R}

    @media (max-width: ${tabletBreakPoint}px) {
      display: inline-block;
    }
  `,
};

export default SearchStyledComponents;
