import { FC, useContext } from "react";
import Link from "next/link";

import { core, icons } from "@r114dev/rds";

import { SidePanelMenuProps } from "./types";
import SidePanelMenuStyledComponents from "./styled";

import { Menu } from "../../models/menu";

import { getChildrenMenusFromAllMenus } from "../../utils/network/menu";
import { MenuContext } from "../Layout/context";

const { Accordion } = core;

const {
  outlined: {
    arrows: { DownSVG },
  },
} = icons;

const { MenuList, MenuListItem, Title, SubTitle } =
  SidePanelMenuStyledComponents;

const SidePanelMenu: FC<SidePanelMenuProps> = ({
  items,
  currentPath,
  isMobile,
  onItemClick,
}) => {
  const { menus } = useContext(MenuContext);

  const handleMenuItemClick = (domain: Menu) => {
    if (onItemClick) {
      onItemClick(domain);
    }
  };

  return (
    <MenuList>
      {items
        .sort((a, b) => a.order - b.order)
        .map((item) => {
          const { title, path, id } = item;
          const currentMenus = getChildrenMenusFromAllMenus(item, menus);

          return currentMenus.length > 0 ? (
            <MenuListItem key={id}>
              <Accordion
                isOpenOnMount={
                  !!currentMenus.find(({ path }) => path === currentPath)
                }
                delay={1}
              >
                {(isOpen) => {
                  return (
                    <>
                      <Accordion.Trigger>
                        <Title
                          theme={{
                            isActive: !!currentPath?.includes(path),
                            isOpen,
                          }}
                        >
                          <div>
                            {title}
                            <DownSVG />
                          </div>
                        </Title>
                      </Accordion.Trigger>
                      <Accordion.Body>
                        <MenuList>
                          {currentMenus
                            .sort((a, b) => a.order - b.order)
                            .map((item) => {
                              return (
                                <MenuListItem
                                  key={item.id}
                                  onClick={() => handleMenuItemClick(item)}
                                >
                                  <Link href={item.path}>
                                    <SubTitle
                                      theme={{
                                        isActive: currentPath === item.path,
                                      }}
                                    >
                                      <div>{item.title}</div>
                                    </SubTitle>
                                  </Link>
                                </MenuListItem>
                              );
                            })}
                        </MenuList>
                      </Accordion.Body>
                    </>
                  );
                }}
              </Accordion>
            </MenuListItem>
          ) : (
            <MenuListItem key={path} onClick={() => handleMenuItemClick(item)}>
              <Link href={path}>
                <Title theme={{ isActive: currentPath === path }}>
                  <div>{title}</div>
                </Title>
              </Link>
            </MenuListItem>
          );
        })}
    </MenuList>
  );
};

export default SidePanelMenu;
