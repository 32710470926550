import {
  getFirestore,
  collection as getCollection,
  query as getQuery,
  getDocs,
  doc,
  getDoc,
  QueryConstraint,
  setDoc,
  updateDoc,
} from "firebase/firestore/lite";

import app from "./app";
const firestore = getFirestore(app);

export async function get(
  collectionName: string,
  ...queryConstraints: QueryConstraint[]
) {
  const collection = getCollection(firestore, collectionName);
  const query = getQuery(collection, ...queryConstraints);
  const snapshot = await getDocs(query);

  return snapshot.docs.map((doc) => doc.data());
}

export async function getOne(collectionName: string, documentName: string) {
  const ref = doc(firestore, collectionName, documentName);
  const snapshot = await getDoc(ref);
  return snapshot.data();
}

export async function set(
  collectionName: string,
  docName: string,
  data: unknown
) {
  await setDoc(doc(firestore, collectionName, docName), data);
}

export async function setOne(
  collectionName: string,
  docName: string,
  data: unknown
) {
  await setDoc(doc(firestore, collectionName, docName), data);
}

export async function updateOne(
  collectionName: string,
  docName: string,
  data: any
) {
  await updateDoc(doc(firestore, collectionName, docName), data);
}
