import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore/lite";
import { get, set } from "../firebase/firestore";

import { Menu } from "../../models/menu";
import { Role } from "../../models/role";

export const getMenus = async (role: Role = "guest") => {
  try {
    if (role === "master") {
      const menus = (await get("menus", orderBy("order"))) as Menu[];
      return menus;
    } else {
      const menus = (await get(
        "menus",
        where("accessRights", "array-contains-any", [role, "guest"]),
        where("visible", "==", true),
      )) as Menu[];
      return menus;
    }
  } catch {
    return [];
  }
};

export const setMenu = async (menu: Menu) => {
  await set("menus", menu.id, menu);
};

export const deleteMenu = async (id: string) => {
  const ref = doc(getFirestore(), "menus", id);
  await deleteDoc(ref);
};

export const getMenuByPath = async (path: string, hasPost?: boolean) => {
  if (hasPost) {
    const q = query(
      collection(getFirestore(), "menus"),
      where("path", "==", path),
    );

    const menu = await getDocs(q);

    const doc = menu.docs.find((doc) => {
      return doc.data().postId;
    });

    return doc?.data() as Menu;
  } else {
    const q = query(
      collection(getFirestore(), "menus"),
      where("path", "==", path),
      limit(1),
    );

    const menu = await getDocs(q);

    return menu.docs[0].data() as Menu;
  }
};

export const getMenuById = async (id: string) => {
  const ref = doc(getFirestore(), "menus", id);
  const menu = await getDoc(ref);

  return menu.data() as Menu;
};

/**
 * 메뉴 객체의 children(하위 메뉴 ID 배열)을 활용하여
 * 전체 메뉴로부터 하위 메뉴 객체를 리턴하는 함수
 *
 * @param menu
 * @param menus
 * @returns
 */
export const getChildrenMenusFromAllMenus = (menu: Menu, menus: Menu[]) => {
  return menus.filter(({ id }) => menu.children.includes(id));
};

/**
 * 최상위 메뉴를 리턴하는 함수
 */
export const getParentMenus = (menus: Menu[]) => {
  return menus
    .filter(({ parentId }) => !parentId)
    .sort((a, b) => a.order - b.order);
};
