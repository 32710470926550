"use client";

import { FC } from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { QueryProviderProps } from "./types";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {},
  },
});

const QueryProvider: FC<QueryProviderProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};

export default QueryProvider;
