import { FC, MouseEvent, useMemo } from "react";

import { IconButtonProps } from "./types";
import IconButtonStyledComponents from "./styled";

import { icons } from "@r114dev/rds";

const {
  outlined: {
    base: { SearchSVG, HamburgerButtonSVG },
    character: { CloseSmallSVG },
  },
} = icons;

const { StyledButton } = IconButtonStyledComponents;

const IconButton: FC<IconButtonProps> = ({ type, onClick, ...props }) => {
  const svg = useMemo(() => {
    switch (type) {
      case "close":
        return <CloseSmallSVG />;
      case "hamburger":
        return <HamburgerButtonSVG />;
      case "search":
        return <SearchSVG />;
    }
  }, [type]);

  const handleClick = (e: MouseEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  return (
    <StyledButton onClick={handleClick} {...props}>
      {svg}
    </StyledButton>
  );
};

export default IconButton;
