import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import { tabletBreakPoint } from "../../constants/size";

const { getColor, typography } = foundations;

const SearchResultStyledComponents = {
  Container: styled.div<{ rect: DOMRect }>`
    width: 1080px;
    max-height: 500px;
    overflow-y: auto;

    position: fixed;
    top: ${({ rect }) => rect.top + rect.height + 8}px;
    left: ${({ rect }) => rect.left}px;
    z-index: 10;

    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.04);

    ${getColor("background", "white", "black")};

    @media (max-width: ${tabletBreakPoint}px) {
      width: 100%;
      max-height: calc(100% - 64px);

      top: 64px;
      left: 0;
    }
  `,

  Header: styled.header`
    padding: 24px 60px 0;

    ${getColor("color", "gray600", "gray400")};

    ${typography.body01B}

    @media (max-width: ${tabletBreakPoint}px) {
      padding: 10px 24px 0;
    }
  `,

  ResultList: styled.ul`
    padding: 0;
    list-style-type: none;
    li {
      padding: 24px 72px;

      border-bottom: 1px solid;
      ${getColor("border-bottom-color", "gray200", "gray800")};
    }

    @media (max-width: ${tabletBreakPoint}px) {
      li {
        padding: 12px 36px;
      }
    }
  `,
  Category: styled.div`
    ${getColor("color", "gray600", "gray400")};

    ${typography.body03R}
  `,
  Title: styled.div`
    ${getColor("color", "gray900", "gray100")};

    ${typography.heading04B}

    b {
      ${getColor("color", "blue500")};
    }

    cursor: pointer;
  `,
  Placeholder: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    padding: 32px 0;

    ${getColor("color", "gray600", "gray400")};
    ${typography.body03R}
  `,
};

export default SearchResultStyledComponents;
