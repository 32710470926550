import { FC } from "react";

import { LabelProps } from "./types";
import LabelStyledComponents from "./styled";

const { Container } = LabelStyledComponents;

const Label: FC<LabelProps> = ({ children, onClick }) => {
  return <Container onClick={onClick}>{children}</Container>;
};

export default Label;
