import { FC, MouseEvent } from "react";

import { DimProps } from "./types";
import DimStyledComponents from "./styled";

const { Container } = DimStyledComponents;

const Dim: FC<DimProps> = ({
  children,
  center = true,
  stopPropagation,
  onClick,
}) => {
  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    if (onClick) {
      onClick(e);
    }
    if (stopPropagation) {
      e.stopPropagation();
    }
  };

  return (
    <Container theme={{ center }} onClick={handleClick}>
      {children}
    </Container>
  );
};

export default Dim;
