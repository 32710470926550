import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import { tabletBreakPoint } from "../../constants/size";

const { getColor, typography } = foundations;

const SearchInputStyledComponents = {
  Container: styled.div`
    width: 100%;
    height: 60px;

    display: flex;
    align-items: center;
    gap: 4px;

    padding: 8px 24px;
    border-radius: 4px;
    ${getColor("background", "gray100", "gray900")};

    @media (max-width: ${tabletBreakPoint}px) {
      height: 40px;
      padding: 8px;
    }
  `,

  StyledInput: styled.input`
    width: 100%;
    background: none;
    border: none;
    outline: none;

    ${typography.body02R}

    &::placeholder {
      ${getColor("color", "gray400", "gray600")};
    }
  `,

  CloseButton: styled.button`
    border: none;
    background: none;
    padding: 0;

    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    @media (max-width: ${tabletBreakPoint}px) {
      svg {
        width: 16px;
        height: 16px;
      }
    }
  `,
};

export default SearchInputStyledComponents;
