import { useEffect, useRef } from "react";

const useDebounce = (action: () => void, delay: number) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      action();
    }, delay);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [action, delay]);
};

export default useDebounce;
