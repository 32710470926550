import styled from "styled-components";
import { foundations } from "@r114dev/rds";

import { SubTitleProps, TitleProps } from "./types";

import { tabletBreakPoint, transitionDelay } from "../../constants/size";

const { getColor, typography } = foundations;

const SidePanelMenuStyledComponents = {
  MenuList: styled.ul`
    padding: 0;
    list-style-type: none;
  `,
  MenuListItem: styled.li`
    cursor: pointer;
  `,

  Title: styled.div<{ theme: TitleProps }>`
    padding: 0 16px;

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;

      padding: 12px 16px;

      ${({ theme: { isActive } }) =>
        isActive
          ? getColor("color", "gray900", "gray100")
          : getColor("color", "gray600", "gray400")};

      ${({ theme: { isActive } }) =>
        isActive ? typography.body01B : typography.body01M};

      > svg {
        width: 16px;
        height: 16px;

        ${({ theme: { isActive } }) =>
          isActive
            ? getColor("fill", "gray900", "gray100")
            : getColor("fill", "gray600", "gray400")};

        transition: ${transitionDelay}s;
        transform: rotate(
          ${({ theme: { isOpen } }) => (!isOpen ? "0deg" : "180deg")}
        );
        will-change: transform;

        defs {
          clipPath {
            rect {
              width: 16px;
              height: 16px;
            }
          }
        }
      }
    }

    &:hover {
      div {
        ${getColor("background-color", "gray100", "gray900")};
        border-radius: 8px;
      }
    }

    @media (max-width: ${tabletBreakPoint}px) {
      div {
        padding: 12px 24px;

        ${({ theme: { isActive } }) =>
          isActive
            ? getColor("color", "gray900", "gray100")
            : getColor("color", "gray600", "gray400")};

        ${({ theme: { isActive } }) =>
          isActive ? typography.heading04B : typography.heading04M};
        > svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  `,

  SubTitle: styled.div<{ theme: SubTitleProps }>`
    padding: 0 16px;

    div {
      padding: 10px 32px;

      ${({ theme: { isActive } }) =>
        isActive
          ? getColor("color", "gray900", "gray100")
          : getColor("color", "gray600", "gray400")};

      ${({ theme: { isActive } }) =>
        isActive ? typography.body02B : typography.body02M};
    }

    &:hover {
      div {
        border-radius: 8px;
        ${getColor("background-color", "gray100", "gray900")};
      }
    }

    @media (max-width: ${tabletBreakPoint}px) {
      div {
        padding: 10px 40px;
        ${({ theme: { isActive } }) =>
          isActive
            ? getColor("color", "gray900", "gray100")
            : getColor("color", "gray600", "gray400")};

        ${({ theme: { isActive } }) =>
          isActive ? typography.body01B : typography.body01M};
      }
    }
  `,
};

export default SidePanelMenuStyledComponents;
