"use client"

import { createContext } from "react";

import { Menu } from "../../models/menu";
import { User } from "firebase/auth";

export const MenuContext = createContext<{
  menus: Menu[];
  setMenus: (menus: Menu[]) => void;
}>({
  menus: [],
  setMenus: () => {},
});

export const UserContext = createContext<{
  user: User | null;
  setUser: (user: User | null) => void;
}>({
  user: null,
  setUser: () => {},
});

