"use client";

import { FC, useContext, useEffect, useState } from "react";

import Link from "next/link";
import { usePathname, useRouter } from "next/navigation";

import { User } from "firebase/auth";

import { LayoutProps } from "./types";
import LayoutStyledComponents from "./styled";
import { MenuContext, UserContext } from "./context";

import QueryProvider from "../QueryProvider";
import PostProvider from "../PostProvider";

import HamburgerDrawer from "../HamburgerDrawer";
import Search from "../Search";

import LogoSVG from "../../assets/svgs/Logo.svg";

import { getRole } from "../../utils/network/role";
import { auth } from "../../utils/firebase/app";
import { deleteAuth, setAuth } from "../../utils/network/auth";
import { getMenus } from "../../utils/network/menu";
import { Menu } from "../../models/menu";

const {
  Container,
  Header,
  LogoArea,
  Nav,

  MobileHeader,
  MobileHeaderButtonGroup,
} = LayoutStyledComponents;

const Layout: FC<LayoutProps> = ({ children }) => {
  const [menus, setMenus] = useState<Menu[]>([]);
  const [user, setUser] = useState<null | User>(null);

  const { push } = useRouter();

  useEffect(() => {
    auth.onAuthStateChanged(async (newUser) => {
      if (newUser) {
        const token = await newUser.getIdToken();
        const role = await getRole(newUser.uid);
        const menus = await getMenus(role);

        setMenus(menus);

        await fetch("/auth", {
          method: "POST",
          body: JSON.stringify({ token }),
        });
        await setAuth(newUser.uid, {
          token,
          role,
        });
      } else {
        const menus = await getMenus();
        setMenus(menus);
      }

      setUser(newUser);
    });
  }, []);

  const pathname = usePathname();

  const hasHeader = pathname !== "/";

  return (
    <QueryProvider>
      <UserContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        <MenuContext.Provider value={{ menus, setMenus }}>
          <PostProvider>
            <Container>
              {hasHeader && <HeaderComponent />}
              {children}
              {user && (
                <div
                  style={{ position: "fixed", right: 0, top: 0, zIndex: 9999 }}
                >
                  <button
                    onClick={async () => {
                      await fetch("/auth", { method: "DELETE" });
                      await deleteAuth(user.uid);
                      auth.signOut();
                      push("/");
                    }}
                  >
                    로그아웃
                  </button>
                </div>
              )}
            </Container>
          </PostProvider>
        </MenuContext.Provider>
      </UserContext.Provider>
    </QueryProvider>
  );
};

export default Layout;

export const HeaderComponent = () => {
  const pathname = usePathname();
  const { menus } = useContext(MenuContext);

  return (
    <>
      <Header>
        <LogoArea>
          <Link href="/">
            <LogoSVG />
          </Link>
        </LogoArea>
        <Nav>
          <ul>
            {menus
              .filter(({ visible, parentId }) => visible && !parentId)
              .sort((a, b) => a.order - b.order)
              .map(({ path, title }) => {
                const currentDomain = pathname.split("/")?.[1];
                const isActive = `${path.split("/")[1]}` === currentDomain;

                return (
                  <li key={path} className={isActive ? "active" : undefined}>
                    <Link href={path}>{title}</Link>
                  </li>
                );
              })}
          </ul>
          <Search />
        </Nav>
      </Header>
      <MobileHeader>
        <LogoArea>
          <Link href="/">
            <LogoSVG />
          </Link>
        </LogoArea>
        <MobileHeaderButtonGroup>
          <Search />
          <HamburgerDrawer />
        </MobileHeaderButtonGroup>
      </MobileHeader>
    </>
  );
};
