import { useEffect } from "react";

const useScrollDisable = (enable: boolean = true) => {
  useEffect(() => {
    if (enable) {
      document.body.style.setProperty("overflow", "hidden");

      return () => {
        document.body.style.removeProperty("overflow");
      };
    }
  }, [enable]);
};

export default useScrollDisable;
