import { deleteDoc, doc, getFirestore } from "firebase/firestore/lite";
import { Auth } from "../../models/auth";
import { set } from "../firebase/firestore";

export const setAuth = async (id: string, auth: Auth | null) => {
  await set("auth", id, auth);
};

export const deleteAuth = async (id: string) => {
  const ref = doc(getFirestore(), "auth", id);
  await deleteDoc(ref);
};
