import { FC } from "react";

import { core } from "@r114dev/rds";
import { DialogContextValue } from "@r114dev/rds/dist/types/core/Dialog";

import { DrawerProps } from "./types";
import DrawerStyledComponents from "./styled";

import Dim from "../Dim";
import useScrollDisable from "../../hooks/useScrollDisable";

const { Dialog } = core;

const { Container } = DrawerStyledComponents;

const Drawer: FC<DrawerProps> = ({ trigger, children }) => {
  return (
    <Dialog>
      {(ctx) => {
        return (
          <>
            <Dialog.Trigger>{trigger}</Dialog.Trigger>
            <Dialog.Body>
              <DrawerBody ctx={ctx}>{children}</DrawerBody>
            </Dialog.Body>
          </>
        );
      }}
    </Dialog>
  );
};

const DrawerBody = ({
  ctx,
  children,
}: {
  ctx: DialogContextValue;
  children: DrawerProps["children"];
}) => {
  useScrollDisable();

  return (
    <Dim onClick={ctx.handleClose}>
      <Container theme={{ isShow: true }} onClick={(e) => e.stopPropagation()}>
        {children(ctx)}
      </Container>
    </Dim>
  );
};

export default Drawer;
