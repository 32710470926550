import {
  ChangeEvent,
  ForwardRefRenderFunction,
  forwardRef,
  useRef,
} from "react";

import { icons } from "@r114dev/rds";

import { SearchInputProps } from "./types";
import SearchInputStyledComponents from "./styled";

const {
  outlined: {
    base: { SearchSVG },
  },
  filled: {
    character: { CloseOncSVG },
  },
} = icons;

const { Container, StyledInput, CloseButton } = SearchInputStyledComponents;

const SearchInput: ForwardRefRenderFunction<
  HTMLDivElement,
  SearchInputProps
> = ({ value, onChange }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCloseClick = () => {
    onChange("");

    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    onChange(value);
  };

  return (
    <Container ref={ref}>
      <SearchSVG width={16} height={16} />
      <StyledInput
        ref={inputRef}
        placeholder="검색어를 입력해 주세요."
        value={value}
        onChange={handleInputChange}
        autoFocus
      />
      {value && (
        <CloseButton onClick={handleCloseClick}>
          <CloseOncSVG />
        </CloseButton>
      )}
    </Container>
  );
};

export default forwardRef(SearchInput);
