import { FC, Fragment, ReactElement, useMemo } from "react";
import { createPortal } from "react-dom";

import { SearchResultProps } from "./types";
import SearchResultStyledComponents from "./styled";

import { Menu } from "../../models/menu";

const { Container, Header, ResultList, Category, Title, Placeholder } =
  SearchResultStyledComponents;

const SearchResult: FC<SearchResultProps> = ({
  results,
  keyword,
  rect,
  onTitleClick,
}) => {
  const resultsGroupByDomain = useMemo(() => {
    try {
      const group: any = {};

      results.forEach((result) => {
        let category = result.path.split("/")[1];
        category = category[0].toUpperCase() + category.slice(1);

        if (group[category] === undefined) {
          Object.assign(group, { [category]: [] });
        }
        group[category].push(result);
      });

      return group;
    } catch (e) {
      return undefined;
    }
  }, [results]);

  const renderHighlightTitle = (title: string) => {
    if (keyword) {
      const arr = [];
      const lowerTitle = title.toLowerCase();
      const lowerKeyword = keyword.toLowerCase();

      let length = lowerTitle.length;

      let end = 0;
      while (length > 0) {
        const start = lowerTitle.indexOf(lowerKeyword, end);
        if (start > -1) {
          if (end === 0) {
            arr.push(title.slice(0, start));
          } else {
            arr.push(title.slice(end, start));
          }
          end = start + lowerKeyword.length;
          arr.push(<b>{title.slice(start, end)}</b>);
        } else {
          arr.push(title.slice(end, title.length));
          break;
        }
      }
      return arr.map((el, index) => <Fragment key={index}>{el}</Fragment>);
    } else {
      return title;
    }
  };

  return rect
    ? createPortal(
        (
          <Container rect={rect}>
            {keyword && results.length === 0 ? (
              <Placeholder>No results found</Placeholder>
            ) : (
              Object.entries<Menu[]>(resultsGroupByDomain || {}).map(
                ([category, domains]) => {
                  return (
                    <div key={category}>
                      <Header>{category}</Header>

                      <ResultList>
                        {domains.map((domain) => {
                          return (
                            <li key={domain.id}>
                              <Category>
                                {category} / {domain.title}
                              </Category>
                              <Title
                                onClick={() => {
                                  if (onTitleClick) {
                                    onTitleClick(domain);
                                  }
                                }}
                              >
                                {renderHighlightTitle(domain.title)}
                              </Title>
                            </li>
                          );
                        })}
                      </ResultList>
                    </div>
                  );
                },
              )
            )}
          </Container>
        ) as any,
        document.body,
      )
    : null;
};

export default SearchResult;
