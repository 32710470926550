import styled from "styled-components";
import { foundations } from "@r114dev/rds";
import {
  headerHeight,
  mobileHeaderHeight,
  sideMargin,
  tabletBreakPoint,
  transitionDelay,
} from "../../constants/size";

const { getColor, typography } = foundations;

const LayoutStyledComponents = {
  Container: styled.div`
    width: 100%;
    height: 100%;
  `,
  Header: styled.header`
    width: inherit;
    min-width: inherit;
    height: ${headerHeight}px;

    display: flex;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    padding: 0 40px;

    background: rgba(255, 255, 255, 0.92);

    backdrop-filter: blur(4px);

    border-bottom: 1px solid;
    ${getColor("border-bottom-color", "gray200", "gray800")}

    transition: ${transitionDelay}s;

    @media (max-width: ${tabletBreakPoint}px) {
      top: -100%;
    }
  `,

  LogoArea: styled.div`
    width: 240px;

    display: flex;
    align-items: center;

    svg {
      width: 116px;
      height: 32px;
    }

    @media (max-width: ${tabletBreakPoint}px) {
      width: auto;
      flex: 1;
    }
  `,
  Nav: styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;

    ul {
      display: flex;
      padding: 0;
      list-style-type: none;

      li {
        padding: 24px 32px;
        ${getColor("color", "gray400")};

        a {
          ${typography.heading04B}
        }

        &.active {
          ${getColor("color", "black", "white")};
        }
      }
    }
  `,

  MobileHeader: styled.header`
    width: 100%;
    height: ${mobileHeaderHeight}px;
    
    display: flex;
    justify-content: flex-end;

    position: fixed;
    top: -100%;
    right: 0;
    z-index: 10;

    padding: 12px ${sideMargin}px;
    ${getColor("background", "white", "black")}

    border-bottom: 1px solid;
    ${getColor("border-bottom-color", "gray200", "gray800")}

    transition: ${transitionDelay}s;

    @media (max-width: ${tabletBreakPoint}px) {
      top: 0;
    }
  `,
  MobileHeaderButtonGroup: styled.div`
    display: flex;
    gap: 8px;
  `,
};

export default LayoutStyledComponents;
