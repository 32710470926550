import styled from "styled-components";

import { foundations } from "@r114dev/rds";

const { getColor, typography } = foundations;

import {
  mobileHeaderHeight,
  sideMargin,
  tabletBreakPoint,
  transitionDelay,
} from "../../constants/size";

const HamburgerDrawerStyledComponents = {
  Header: styled.header`
    width: 100%;
    height: ${mobileHeaderHeight}px;

    display: flex;
    justify-content: flex-end;

    position: fixed;
    top: -100%;
    right: 0;
    z-index: 10;

    padding: 12px ${sideMargin}px;

    ${getColor("background", "white", "black")};

    border-bottom: 1px solid;
    ${getColor("border-bottom-color", "gray200", "gray800")};

    transition: ${transitionDelay}s;

    @media (max-width: ${tabletBreakPoint}px) {
      top: 0;
    }
  `,

  ButtonGroup: styled.div`
    display: flex;
    gap: 8px;
  `,

  Body: styled.div`
    width: 200%;
    height: 100%;

    display: flex;

    transition: ${transitionDelay}s;

    > ul {
      width: 50%;
      overflow-y: auto;
      padding: 24px 0;
      flex-shrink: 0;
      list-style-type: none;
      > li {
        padding: 12px 24px;

        ${getColor("color", "gray900", "gray100")}

        ${typography.heading04B}

        cursor: pointer;
      }
    }
  `,

  MenuListTitle: styled.div`
    display: flex;
    gap: 5px;
    align-items: center;

    padding: 12px 20px;
    cursor: pointer;

    h2 {
      ${getColor("color", "gray900", "gray100")}
      ${typography.heading04B}
    }

    svg {
      ${getColor("fill", "gray900", "gray100")};
    }
  `,
};

export default HamburgerDrawerStyledComponents;
