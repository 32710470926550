import styled from "styled-components";
import { mobileHeaderHeight, transitionDelay } from "../../constants/size";
import { foundations } from "@r114dev/rds";

const { getColor } = foundations;

const DrawerStyledComponents = {
  Container: styled.div<{ theme: { isShow: boolean } }>`
    position: fixed;
    top: 0;
    right: 0;
    z-index: 10;

    padding: ${mobileHeaderHeight}px 0 0;

    transform: translateX(0);

    @keyframes drawerIn {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0);
      }
    }

    animation: drawerIn ${transitionDelay}s;

    width: 80%;
    height: 100%;
    overflow: hidden;

    ${getColor("background", "white", "black")};
  `,
};

export default DrawerStyledComponents;
