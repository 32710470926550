import styled, { css } from "styled-components";

const DimStyledComponents = {
  Container: styled.div<{ theme: { center?: boolean } }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;

    background: rgba(0, 0, 0, 0.2);

    @keyframes fadeIn {
      from {
        background: rgba(0, 0, 0, 0);
      }
      to {
        background: rgba(0, 0, 0, 0.2);
      }
    }
    animation: fadeIn 0.4s;

    ${({ theme: { center } }) => {
      if (center) {
        return css`
          display: flex;
          justify-content: center;
          align-items: center;
        `;
      }
    }}
  `,
};

export default DimStyledComponents;
