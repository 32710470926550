"use client";

import _ from "lodash";

import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useRouter } from "next/navigation";

import { core } from "@r114dev/rds";

import { SearchProps } from "./types";
import SearchStyledComponents from "./styled";

import useDebounce from "../../hooks/useDebounce";

import Label from "../Label";
import IconButton from "../IconButton";
import SearchInput from "../SearchInput";
import SearchResult from "../SearchResult";
import { MenuContext } from "../Layout/context";
import { Menu } from "../../models/menu";

const { Dialog } = core;
const { Container, TopArea, BottonArea, CloseButton } = SearchStyledComponents;

const Search: FC<SearchProps> = () => {
  return (
    <Dialog>
      {({ handleClose }) => {
        return (
          <>
            <Dialog.Trigger>
              <IconButton type="search" onClick={(e) => e.stopPropagation()} />
            </Dialog.Trigger>
            <Dialog.Body>
              <SearchBody onClose={handleClose} />
            </Dialog.Body>
          </>
        );
      }}
    </Dialog>
  );
};

export default Search;

const SearchBody: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { menus } = useContext(MenuContext);
  const { push } = useRouter();

  const inputRef = useRef<HTMLDivElement>(null);

  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState<Menu[]>([]);

  const [inputRect, setInputRect] = useState<DOMRect>();

  useEffect(() => {
    const initializeRect = () => {
      if (inputRef.current) {
        setInputRect(inputRef.current.getBoundingClientRect());
      }
    };
    initializeRect();

    window.addEventListener("click", onClose);
    window.addEventListener("resize", initializeRect);
    return () => {
      window.removeEventListener("click", onClose);
      window.removeEventListener("resize", initializeRect);
    };
  }, [onClose]);

  const search = useCallback(() => {
    setResults([]);

    if (keyword) {
      const results = menus.filter(({ title }) =>
        title.toLowerCase().includes(keyword.toLowerCase()),
      );
      setResults(results);
    } else {
      setResults([]);
    }
  }, [keyword, menus]);

  useDebounce(search, 400);

  const handleLabelClick = (path: string, title: string) => {
    onClose();

    push(path);
  };

  const handleTitleClick = (domain: Menu) => {
    onClose();

    push(domain.path);
  };

  return (
    <Container onClick={(e) => e.stopPropagation()}>
      <TopArea>
        <SearchInput ref={inputRef} value={keyword} onChange={setKeyword} />
        <CloseButton onClick={onClose}>취소</CloseButton>
      </TopArea>
      <BottonArea>
        <h4>추천 키워드</h4>
        <p>
          <Label
            onClick={() =>
              handleLabelClick("/foundations/color-system", "Color")
            }
          >
            Color
          </Label>
          <Label
            onClick={() =>
              handleLabelClick("/components/actions/button", "Button")
            }
          >
            Button
          </Label>
          <Label
            onClick={() => handleLabelClick("/data-lab/bar-chart", "Bar Chart")}
          >
            Bar Chart
          </Label>
        </p>
      </BottonArea>
      <SearchResult
        results={results}
        keyword={keyword}
        rect={inputRect}
        onTitleClick={handleTitleClick}
      />
    </Container>
  );
};
